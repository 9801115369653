/* general */
.main {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  align-self: stretch;
  background-color: white;
  color: rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.main .container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 60px 16px 80px;
}

* {
  box-sizing: border-box;
}

.main h1,
.main h2,
.main h3 {
  margin: 0;
  font-weight: inherit;
}

.main h1 {
  font-size: 24px;
}

.main h2 {
  font-size: 24px;
}

.main p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.3em;
}

/* after header */
.main .after-header {
  background-color: #f73e1f;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;

  /* font-weight: 400; */
  text-align: center;
  padding: 5px;
}

/* about */
.main .about {
  text-align: center;
  margin-bottom: 60px;
}

.main .about h3 {
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
}

.main .about h2 {
  margin-bottom: 8px;
  font-weight: 400;
}

/* mockups */

.main .mockups {
  padding: 60px 16px 80px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.main .mockups img {
  width: 100%;
  height: 100%;
  max-width: 250px;
  border-radius: 24px;
}

/* footer */
.main footer {
  background-color: #262f36;
  color: rgba(255, 255, 255, 0.87);
}

.main footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 64px;
}

.main footer .container > * {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.main footer .stores {
  max-width: 400px;
  flex-direction: row;
}

.main footer .stores img {
  width: 100%;
}

.main footer .stores > *:first-child {
  margin-right: 16px;
}

.main footer .contact-info {
  display: flex;
  margin-bottom: 8px;
}

.main footer a {
  color: rgba(255, 255, 255, 0.54);
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.main footer a:hover {
  color: white;
}

.main .contact-info svg {
  fill: white;
  margin-right: 16px;
  width: 24px;
}

/* .main footer a span {
	background-color: white;
	display: inline-flex;
	width: 36px;
	height: 36px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-right: 16px;
} */

/* partners */
.main .partners {
  background-color: #f73e1f;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.main .partners img {
  height: 40px;
  margin: 0 16px;
}

/* @media screen and (max-width: 900px) {
  .main .mockups {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
} */
