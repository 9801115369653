.mainMenu {
  display: flex;
  font-size: 16px;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  justify-content: center;
  width: 100%;
  z-index: 3;
  /* opacity: 0.9; */
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.14); */

  --red-bg: #ff7961;
  --red-border: 6px solid #f44336;
  --yellow-bg: #ffff5a;
  --yellow-border: 6px solid #ffee58;
  --green-bg: #66bb6a;
  --green-border: 6px solid #338a3e;
}

.mainMenu:not(.subMenu)::before {
  flex-grow: 1;
  content: "";
  display: block;
  border-bottom: 6px solid #ddd;
}
.mainMenu:not(.subMenu)::after {
  flex-grow: 1;
  content: "";
  display: block;
  border-bottom: 6px solid #ddd;
}
/* 
.mainMenu div {
	display: flex;
	align-items: center;
	justify-content: center;
} */

.mainMenu .main-menu-container {
  max-width: 1200px;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
  letter-spacing: 0.05em;
  width: 100%;
}

.mainMenu a {
  flex: 1;
  display: flex;
  height: 56px;
  align-items: stretch;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.88);
}

.mainMenu a[data-name="lang"] {
  overflow: visible;
}

.mainMenu a figure {
  flex-grow: 1;
  transition: all 0.15s ease-in-out;
  border-bottom: 6px solid #ddd;
  box-sizing: border-box;
}

.mainMenu a.active figure {
  background: var(--green-bg);
  border-bottom: var(--green-border);
}

@media (hover: hover) {
  .mainMenu a:hover figure {
    background: var(--green-bg);
    border-bottom: var(--green-border);
  }
}

/** order unseen */
.mainMenu a.active.order-unseen figure {
  background-color: var(--red-bg);
  border-bottom: var(--red-border);
}

.mainMenu a.order-unseen:hover figure {
  background-color: var(--red-bg);
  border-bottom: var(--red-border);
}

.mainMenu a.order-unseen figure {
  position: relative;
  border-bottom: var(--red-border) !important;
}

/** call unseen */
.mainMenu a.active.call-unseen figure {
  background-color: var(--yellow-bg);
  border-bottom: var(--yellow-border);
}

.mainMenu a.call-unseen:hover figure {
  background-color: var(--yellow-bg);
  border-bottom: var(--yellow-border);
}

.mainMenu a.call-unseen figure {
  position: relative;
  border-bottom: var(--yellow-border) !important;
}

/** bill unseen */
.mainMenu a.active.bill-unseen figure {
  background-color: var(--yellow-bg);
  border-bottom: var(--yellow-border);
}

.mainMenu a.bill-unseen:hover figure {
  background-color: var(--yellow-bg);
  border-bottom: var(--yellow-border);
}

.mainMenu a.bill-unseen figure {
  position: relative;
  border-bottom: var(--yellow-border) !important;
}

/** reservations unseen  */
.mainMenu a.active.reservation-unseen figure {
  background-color: var(--red-bg);
  border-bottom: var(--red-border);
}

.mainMenu a.reservation-unseen:hover figure {
  background-color: var(--red-bg);
  border-bottom: var(--red-border);
}

.mainMenu a.reservation-unseen figure {
  position: relative;
  border-bottom: var(--red-border) !important;
}

.mainMenu a.order-unseen figure::after,
.mainMenu a.call-unseen figure::after,
.mainMenu a.reservation-unseen figure::after,
.mainMenu a.bill-unseen figure::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  z-index: 999;
  left: -20%;
  width: 20%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 33%,
    rgba(255, 255, 255, 0.9) 68%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 6px;
  border-radius: 1px;
  animation: move 3s cubic-bezier(0.86, 0, 0.07, 1) infinite;
}

.mainMenu [data-name="orders"] figure,
.mainMenu [data-name="reservation"] figure {
  box-sizing: border-box;
}

.mainMenu figure {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mainMenu [data-name="hamburgerMenu"],
.mainMenu [data-name="logout"],
.mainMenu [data-name="lang"],
.mainMenu [data-name="close"] {
  box-sizing: border-box;
  flex: none;
  font-size: 16px !important;
}

.mainMenu [data-name="logout"],
.mainMenu [data-name="report"] {
  border-right: none;
}

.mainMenu .knobMarker {
  position: relative;
  width: 0px;
  height: 0px;
}

.mainMenu .knobMarker div {
  font-weight: 700;
  position: absolute;
  bottom: -2px;
  left: 5px;
  background: #f44336;
  border-bottom: 2px solid darkred;
  border-radius: 3px;
  color: white;
  padding: 2px 5px 0px 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 400px) {
  .mainMenu {
    font-size: 14px;
    line-height: 18px;
  }
  .mainMenu.subMenu {
    font-size: 18px;
  }

  .mainMenu [data-name="lang"] figure {
    padding: 12px 2px 12px;
  }
}

.mainMenu [data-name="lang"] {
  display: flex;
  flex-direction: column;
}

.langOptions {
  position: relative;
  width: 100%;
  height: 0px;
  z-index: 150;
}

.langOptions ul {
  position: absolute;
  top: 0px;
  background: #333;
  min-width: 100%;
  border-bottom: 5px solid #ccc;
  color: #fff;
}

.langOptions ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  cursor: pointer;
}

.langOptions ul li:hover {
  background: #fff;
  color: #333;
}

.mainMenu.subMenu {
  font-size: 18px;
  opacity: 1;
}

.subMenu {
  top: 47px;
  /* border-bottom: 5px solid #ccc; */
  padding: 10px;
  z-index: 90;
}

.subMenu .sub-menu-container {
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.subMenu a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  /* padding: 16px 40px; */
  /* border-bottom: 1px solid #ddd !important; */
  box-sizing: border-box;
  border-radius: 2px;
}

.subMenu a i {
  margin-right: 24px;
}

.subMenu [data-name="report"] {
  border-bottom: none !important;
}

.subMenu a div {
  margin-right: 8px;
}

.subMenu a:hover {
  background-color: #444;
  color: white;
}

@keyframes move {
  0% {
    left: -20%;
  }

  100% {
    left: 100%;
  }
}

.admin-selector {
  /* width: 100%;
  height: 56px;
  
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 3;
  background-color: #fff;
 
  padding: 0 20px; */
  border-bottom: 6px solid #ddd;
}
