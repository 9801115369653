* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
Resetting:
*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*----------------------------------------------*/

window,
body {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-size: 16px;
}

#root {
  color: black;
}

.boxShadow {
  -webkit-box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.31);
}

.roundBox {
  -moz-border-radius: 6px 6px 6px 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px 6px 6px 6px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

a,
a:active,
a:visited {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

a:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

/* Browser specific (not valid) styles to make preformatted text wrap */

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

input,
textarea {
  outline: none;
  box-sizing: border-box;
  padding: 0px 16px;
  /* border: 0; */
  border: 1px solid #eedc9a;
  background-color: #fef8e2;
  border-radius: 2px;
  resize: none;
  transition-duration: 0.3s;
  transition-property: opacity;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-size: 16px;
  height: 40px;
}
textarea {
  padding-top: 16px;
}

input:hover,
textarea:hover {
  border-color: #dab326;
}

input:focus,
textarea:focus {
  border-color: #e6ca68;
  box-shadow: 0px 0px 6px #dab326;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Toastify__toast {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-size: 16px;
  /* text-transform: uppercase; */
  font-weight: 900;
}
.Toastify__toast--default {
  background: #fff !important;
  color: #aaa !important;
}
.Toastify__toast--info {
  background: #5faadb !important;
}
.Toastify__toast--success {
  background: #6dbc81 !important;
}
.Toastify__toast--warning {
  background: #f1d766 !important;
}
.Toastify__toast--error {
  background: #e74c3c !important;
}

/* #root {
	display: flex;
	align-items: center;
	justify-content: center;
} */

button {
  outline-style: none;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-size: 1.02rem;
  font-weight: bold;
  border: none;
  /* background: #fff; */
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  /* min-width: 42px; */
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  box-sizing: border-box;
  padding: 0 0;

  letter-spacing: 0.02em;
  /* margin: 2px 3px;
	border-radius: 4px;
	border: 2px solid #333; */
}

button:disabled {
  pointer-events: none;
  opacity: 0.44;
  cursor: not-allowed !important;
}

button.btn2 {
  background: #fff;
  margin: 4px;
  border-radius: 4px;
  border: 2px solid #333;
  min-width: 36px;
  min-width: 36px;
}

button.btn2:hover {
  background: #ccc;
}

button .icon {
  /* font-size: 1.1rem; */
  display: flex;
  align-items: center;
  justify-content: center;

  transition-duration: 0.1s;
  transition-property: font-size;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-property: font-size;
}

button > i {
  display: inline-flex !important;
}

button .icon:hover {
  font-size: 1.7rem;
}
button::-moz-focus-inner {
  border: 0;
}

button.btn1 {
  padding: 0 10px;
  /* border: 1px solid #ddd; */
  border-bottom: 4px solid rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  transition: opacity 0.1s ease-in-out;
  background: #ccc;
  font-size: 16px;
  padding: 0 16px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  height: 36px;
}

button.btn1 svg {
  margin-right: 6px;
  font-size: 0.8em;
}

button.btn1:hover {
  opacity: 0.8;
  /* background: #333; */
  /* color: white; */
  /* border-bottom: 4px solid #666; */
}

button.btn1:active {
  border-bottom: 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14) inset;
}

button.btn1:focus:not(:active) {
  outline: 2px dotted black;
}

.btn-success {
  padding: 0px 16px;
  background: #1faa00;
  color: white;
  border-bottom: 4px solid #007900;
  border-radius: 2px;
  letter-spacing: 0.06px;
  text-transform: uppercase;
  font-size: 16px;
  min-height: 36px;
  letter-spacing: 0.07em;
  transition: opacity 0.1s ease-in-out;
}

.btn-success:hover {
  opacity: 0.8;
}

.btn-success:active {
  border-bottom: 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14) inset;
}

.btn-success:focus:not(:active) {
  outline: 2px dotted black;
}

.btn-danger {
  font-size: 16px;
  min-height: 36px;
  color: white;
  background-color: #d50000;
  border-bottom: 4px solid #9b0000;
  border-radius: 2px;
  padding: 0 16px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.round-corners {
  border-radius: 18px;
}

/* accessible buttons */
.acc-btn {
  cursor: pointer;
  height: 36px;
  font-size: 16px;
  border-radius: 5px;
  background: none;
  border: 0;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;
  // text-transform: uppercase;
  // letter-spacing: 0.02em;
  font-weight: bold;
  color: rgba(black, 0.6);

  &--icon {
    width: 36px;
    font-size: 18px;
    &:hover {
      // background-color: rgba(black, $alpha: 0.08);
      color: black;
    }

    &:focus {
      outline: 2px solid rgba($color: #000000, $alpha: 0.7);
      color: #f73e1f;
    }
  }

  &--icon-fill-up {
    font-size: 18px;
    width: 48px;
    height: 48px;

    &:hover {
      color: black;
      background-color: rgba(black, $alpha: 0.08);
    }

    &:focus {
      background-color: rgba(black, $alpha: 0.14);
      color: black;
      outline: 2px solid rgba($color: #000000, $alpha: 0.7);
    }
  }

  &--icon-filled {
    font-size: 18px;
    width: 48px;
    height: 48px;
    background-color: rgba(black, $alpha: 0.08);
    color: rgba($color: black, $alpha: 0.8);

    &:hover {
      background-color: rgba(black, $alpha: 0.14);
      color: black;
    }

    &:focus {
      color: black;
      background-color: rgba(black, $alpha: 0.24);
      box-shadow: 0 0 0 2px rgba($color: #000000, $alpha: 0.5);
    }
  }

  &--icon-rounded {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--icon-small {
    width: 36px;
    height: 36px;
  }

  &--text {
    &:hover {
      color: black;
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba($color: #e74c3c, $alpha: 0.5);
    }
  }

  &--filled {
    background-color: rgba(black, $alpha: 0.08);
    color: rgba($color: black, $alpha: 0.8);
    padding: 0 16px;

    &:hover {
      background-color: rgba(black, $alpha: 0.14);
      color: black;
    }

    &:focus {
      color: black;
      background-color: rgba(black, $alpha: 0.24);
      box-shadow: 0 0 0 2px rgba($color: #000000, $alpha: 0.5);
    }
  }

  &--filled-primary {
    background-color: #f73e1f;
    padding: 0 16px;
    color: white;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba($color: #f73e1f, $alpha: 0.5);
    }
  }

  &--filled-danger {
    background-color: #db2000;
    padding: 0 16px;
    color: white;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba($color: #f73e1f, $alpha: 0.5);
    }
  }
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: none;
  }
}
