.simpleModal-overlay {
	background-color: rgba(0, 0, 0, 0.34);
	/* bottom: inherit !important; */
	/* position: ; */
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	z-index: 2;
}

.simpleModal-modal {
	background-color: white;
	margin: 16px;
	box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.14);
	border-radius: 2px;
	width: 100%;
	padding: 16px 24px 8px;
	max-width: 500px;
}

.simpleModal > * + * {
	margin-top: 1em;
}

.simpleModal-modal:focus {
	outline: none;
	box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.14),
		0 0 1px 1px rgba(247, 62, 31, 0.54) inset;
}

.simpleModal h3 {
	margin: 0;
	font-size: 24px;
	letter-spacing: 0.05em;
	line-height: 1.4em;
	color: rgba(0, 0, 0, 0.96);
}

.simpleModal p {
	color: rgba(0, 0, 0, 0.76);
	font-size: 16px;
}

.simpleModal .row {
	display: flex;
	justify-content: flex-end;
}

.simpleModal button {
	padding: 0 8px;
	margin-right: -8px;
	margin-left: 16px;
	font-size: 16px;
}

.simpleModal button:hover {
	color: black;
}
