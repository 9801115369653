/* .languages, */
/* .categories, */
.foods,
.tables,
.setup,
.registration,
.orders,
.EditOrder,
.reservations,
/* .reports, */
.user {
  background: #f9f9f9;
  min-height: CALC(100vh - 44px);
  width: 100%;
  margin: 56px auto 0;
  box-sizing: border-box;
  padding: 16px;
  max-width: 1200px;
}
/* .languages h3, */
/* .categories h3, */
.foods h3,
.tables h3,
.setup h3,
.registration h3,
.orders h3,
.EditOrder h3,
.reservations h3,
/* .reports h3, */
.users h3 {
  font-weight: 500;
  letter-spacing: 0.06em;
  font-size: 24px;
  margin: 24px 0 16px;
  color: rgba(0, 0, 0, 0.8);
  /* text-transform: uppercase; */
}

/* .languages h2, */
/* .categories h2, */
.foods h2,
.tables h2,
.setup h2,
.registration h2,
.orders h2,
.EditOrder h2,
.reservations h2,
/* .reports h2, */
.users h2 {
  font-size: 24px;
  margin: 24px 0 8px;
  color: rgba(0, 0, 0, 0.8);
  /* text-transform: uppercase; */
}

/* .languages h5, */
/* .categories h5, */
.foods h5,
.tables h5,
.setup h5,
.registration h5,
.orders h5,
.EditOrder h5,
.reservations h5,
/* .reports h5, */
.users h5 {
  font-weight: 900;
  font-size: 12px;
  padding: 10px 10px;
  background: #333;
  color: #fff;
  border-radius: 5px;
}
/* .categories [name="createNewBlock"], */
.foods [name='createNewBlock'],
.tables [name='createNewBlock'] {
  width: 100%;
  margin-bottom: 20px;
}
/* .categories [name="createNewBlock"] button, */
.foods [name='createNewBlock'] button,
.tables [name='createNewBlock'] {
  width: 100%;
}

/* .languages figure, */
/* .categories figure, */
.foods figure,
.tables figure,
.zones figure {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 24px;
}
/* .languages figure[name="under_development"], */
/* .categories figure[name="under_development"], */
.foods figure[name='under_development'] {
  background: #faf3da;
}
/* .languages figure[name="published"], */
/* .categories figure[name="published"], */
.foods figure[name='published'],
.tables figure,
.zones figure {
  background: #d9fbd9;
}
/* .foods .addNew {
	display: flex;
	flex-direction: column;
} */

/* .my_languages figure li, */
/* .my_categories figure li, */
.my_foods figure li,
.my_tables figure li,
.my_zones figure li {
  padding: 8px 0;
  border-top: 1px solid #ddd;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .my_languages figure li:first-of-type, */
/* .my_categories figure li:first-of-type, */
.my_foods figure li:first-of-type,
.my_tables figure li:first-of-type,
.my_zones figure li:first-of-type {
  border-top: 0px solid #ddd;
  padding-top: 0px;
}
/* .my_languages [name="primary"], */
/* .my_categories [name="primary"], */
.my_foods [name='primary'] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
/*.my_languages [name='primary'] span, .my_categories [name='primary'] span {*/
/*font-size: 22px;*/
/*}*/
/* .my_languages figure li div, */
/* .my_categories figure li div, */
.my_foods figure li div,
.my_tables figure li div,
.my_zones figure li div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* .my_categories .buttonSeparator, */
/* .my_foods .buttonSeparator, */
.my_tables .buttonSeparator,
.my_zones .buttonSeparator {
  display: inline-block;
  width: 1px;
  height: 25px;
  background: #ccc;
}
.hButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.published {
  font-weight: 900;
  color: #333;
}
.not_published {
  font-weight: 900;
  color: #777;
}
.tabWrapper {
  display: flex;
  align-items: center;
}
/* .tabCircle {
	border: 2px solid #333;
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 5px;
	border-radius: 8px;
	box-sizing: border-box;
} */

[name='createNewBlock'] button.btn1 {
  margin-left: 8px;
}
