.EditOrder {
  margin-bottom: 64px !important;
}

.EditOrder.accepted {
  opacity: 0.5;
}

.EditOrder h3 {
  margin: 32px 0 16px;
  font-size: 24px;
}

.EditOrder .price-sum {
  display: flex;
  font-size: 18px;
  margin-bottom: 32px;
  color: rgba(0, 0, 0, 0.57);
}

.EditOrder .price-sum span {
  color: rgba(0, 0, 0, 0.88);
}

.EditOrder .price-sum > div {
  margin-right: 32px;
}

.EditOrder .bottomNav {
  position: fixed;
  /* width: 100%; */
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.EditOrder .bottomNav .container {
  color: rgba(255, 255, 255, 0.88);
  /* color: rgba(0, 0, 0, 0.57); */
  background-color: #222222;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  font-size: 14px;
  /* font-weight: bold; */
  /* background-color: white; */
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 12px 16px;
  /* border-top: 4px solid rgba(255, 255, 255, 0.1); */
  box-sizing: border-box;
}

.EditOrder .bottomNav .left {
  display: flex;
  flex-direction: column;
}

.EditOrder .bottomNav .left span:first-child {
  margin-bottom: 4px;
  font-size: 14px;
}

.EditOrder .bottomNav .right {
  display: flex;
}

.EditOrder .bottomNav button:first-child {
  margin-right: 8px;
}

@media screen and (max-width: 400px) {
  .EditOrder h3 {
    font-size: 22px !important;
  }

  .EditOrder .price-sum {
    font-size: 16px;
  }
}
