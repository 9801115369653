.landing-header-wrapper {
  width: 100%;
  height: 100vh;
  padding: 24px;
  display: flex;
}

.landing-header {
  flex-grow: 1;
  align-self: stretch;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* public paths in css files no longer supported with react-scripts 4.0.0 */
  /* background-image: url("/media/images/header.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* border-radius: 16px; */
  position: relative;

  /* overflow: hidden; */
  /* border: 1px solid black; */
  /* box-shadow: -2px 2px 20px 5px rgba(0, 0, 0, 0.14); */
  /* border: 4px solid rgba(0, 0, 0, 0.14); */
  /* box-sizing: content-box; */
}

.landing-header:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /* border-radius: 24px; */
  background: linear-gradient(
    to top,
    rgba(247, 62, 31, 0.28) 0%,
    rgba(247, 62, 31, 0) 80%
  );
}

.landing-header nav {
  position: absolute;
  top: 0px;
  right: 1.7%;
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f73e1f; */
  background-color: white;
  padding: 0 24px;
  /* box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.14); */
  z-index: 3;
  /* border-radius: 2px; */
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #999;
  border-top-width: 0;
  width: calc(100% - 1.7% * 2);
  /* transition: background-color 0s; */
}
/* .landing-header.active nav {
	background-color: transparent;
	transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
} */

.landing-header nav a {
  padding: 0 16px;
  font-size: 16px;
  color: black;
}

.landing-header nav a img {
  height: 32px;
  filter: grayscale(100%) contrast(1000%);
}

.landing-header nav a:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.landing-header .header-content {
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  left: 0;
  position: relative;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.landing-header.active .header-content {
  left: 0%;
}

.landing-header .header-content .title {
  max-width: 80%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  /* margin-bottom: 1%; */
}

.landing-header.active .header-content .title {
  opacity: 0.6;
  /* margin-bottom: 1%; */
}

.landing-header .header-content h1 {
  margin-bottom: 36px;
  font-size: 8.2vw;
  text-transform: capitalize;
  /* font-weight: bold; */
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.14);
  /* font-style: italic; */
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.landing-header.active .header-content h1 {
  opacity: 0;
}

.landing-header .header-content .stores {
  max-width: 80%;
  display: flex;
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.landing-header.active .header-content .stores {
  opacity: 0;
}

.landing-header .header-content .stores img {
  height: 100%;
  width: 100%;
}

.landing-header .header-content .stores > *:first-child {
  margin-right: 16px;
}

.landing-header .auth-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: clip-path;
  transition: clip-path 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, translate 0.3s;
  overflow: hidden;
  display: flex;
  clip-path: circle(0.1% at 87% 0);
  transform: translate3d(0, 0, 0);
}

.landing-header.active .auth-overlay {
  clip-path: circle(150% at 87% 0);
  /* transition: clip-path 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s; */
  transform: translate3d(0, 0, 0);
  will-change: clip-path;
}

.landing-header .auth-overlay .auth-left {
  width: 50%;
  background-image: linear-gradient(
    78deg,
    rgba(247, 62, 31, 0.5),
    rgba(247, 62, 31, 1)
  );
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  display: none;
}

.landing-header .auth-overlay .auth-left > div {
  max-width: 80%;
}

.landing-header .auth-overlay .auth-left .title {
  width: 100%;
  filter: drop-shadow(-2px 2px 15px rgba(0, 0, 0, 0.14));
}

.landing-header .auth-overlay .auth-left h2 {
  margin-bottom: calc(36px + 11.9%);
  font-size: 4.1vw;
  text-transform: capitalize;
  text-align: right;
  /* font-weight: bold; */
  /* font-style: italic; */
  /* color: #f73e1f; */
}

.landing-header .auth-overlay .auth-left > .pattern {
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  /* display: none; */
}

/* .landing-header.active .auth-overlay::before {
	height: 100%;
	flex-grow: 1;
	background-color: rgba(247, 62, 31, 0.9);
	display: block;
	content: "";
}

.landing-header.active .auth-overlay::after {
	flex-grow: 1;
	height: 100%;
	background-color: white;
	display: block;
	content: "register";
} */

@media screen and (min-width: 400px) {
  .landing-header nav {
    width: auto;
    /* right: 0; */
    /* top: 0; */
  }
  .landing-header nav a {
    padding: 0 24px;
  }
}

@media screen and (min-width: 800px) {
  /* .landing-header {
    height: 45vw;
  } */

  .landing-header nav {
    /* width: calc(50% - 1.7% * 2); */
  }

  .landing-header .header-content {
    z-index: 1;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    left: 25%;
    position: relative;
    transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .landing-header .header-content .stores {
    max-width: 60%;
  }

  .landing-header .header-content h1 {
    font-size: 4.1vw;
  }

  .landing-header .auth-overlay .auth-left {
    display: flex;
  }

  .landing-header.active .auth-overlay {
    clip-path: circle(100% at 87% 0);
  }
}

@media screen and (min-width: 1200px) {
  /* .landing-header nav {
    right: 1.7%;
    width: auto;
  } */
}
