.require-admin {
  padding-top: 32px;
  padding-bottom: 100px;
  background: #f9f9f9;
  width: 100%;
  margin: 56px auto 0;
  box-sizing: border-box;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  p {
    margin-top: 4em;
  }

  a {
    color: steelblue;
    font-weight: bold;
  }

  &__actions {
    margin-top: 1em;
    display: inline-flex;
    gap: 24px;
    align-items: center;
  }
}
