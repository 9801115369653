body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

* {
  /* font-family: "Roboto", sans-serif !important; */
  box-sizing: border-box;
}

button,
input {
  font-family: "Roboto", sans-serif;
  font-display: swap;
}

.underline {
  border-bottom: 0.5px solid black;
  padding-bottom: 10px;
}
