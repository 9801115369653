.landing-login {
  width: 100%;
  height: 100%;
  background-color: white;
  color: rgba(1, 1, 1, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.landing-login .pattern {
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: white;
  color: #f73e1f;
}

.landing-login > form {
  width: 60%;
  height: 312px;
  max-width: 400px;
  min-width: 288px;
}

.landing-login .landing-input {
  width: 100%;
  position: relative;
  margin-bottom: 36px;
}

.landing-login .mdc-text-field-helper-line {
  top: -36px;
  position: relative;
  height: 0;

  p {
    font-size: 12px;
  }
}

// .landing-login .landing-input.mdc-text-field--focused {
//   @include mdc-text-field-label-color(#f73e1f);
//   @include mdc-text-field-bottom-line-color(#f73e1f);
//   @include mdc-text-field-line-ripple-color(#f73e1f);
// }

// .landing-login .landing-input.mdc-text-field--invalid {
//   @include mdc-text-field-label-color(#ff9100);
//   @include mdc-text-field-bottom-line-color(#ff9100);
//   @include mdc-text-field-line-ripple-color(#ff9100);
//   @include mdc-text-field-helper-text-color(#ff9100);
// }

.landing-login .landing-form-bottom {
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.landing-login .landing-auth-button {
  background-color: #f73e1f;
  color: white;
  padding: 0 24px;
  margin: 32px 0 0 0;
  text-transform: uppercase;
  height: 36px;
  /* border-radius: 2px; */
  justify-self: stretch;
  font-size: 16px;
}

.landing-login .landing-auth-button:hover {
  opacity: 0.8;
}

.landing-login .landing-auth-button:focus {
  box-shadow: 0 0 0 1px white, 0 0 0 3px #f73e1f;
}

.landing-login .landing-form-bottom .screen-counter {
  padding: 0px 8px;
  height: 36px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f73e1f;
  border-radius: 4px;
}

.landing-login .fade-enter {
  opacity: 0;
  transform: translateX(50px);
}
.landing-login .fade-enter-active {
  opacity: 1;
  transform: translateX(0);
}

.landing-login .fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.landing-login .fade-exit-active {
  opacity: 0;
  transform: translateX(-50px);
}
.landing-login .fade-enter-active,
.landing-login .fade-exit-active {
  transition: opacity 500ms, transform 500ms;
}

@media screen and (min-width: 800px) {
  .landing-login {
    width: 50%;
  }

  .landing-login > form {
    width: calc(100% - 1.7% * 16);
  }

  .landing-login .pattern {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .landing-login > form {
    width: 60%;
  }
}
