/* general */
.privacy-layout {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  color: rgba(0, 0, 0, 0.87);
  align-self: stretch;
  background-color: white;
  max-width: 1040px;
  margin: 0 auto;
  padding: 60px 24px 80px;
}

.privacy-layout .privacy-links {
  display: flex;
  gap: 24px;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 60px;
}

.privacy-layout .privacy-links ul {
  display: flex;
  gap: 16px;
}

.privacy-layout .privacy-links li {
  font-weight: 500;
}

.privacy-layout .privacy-links li .MuiChip-root:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 4px black;
}

.privacy-layout .privacy-links li[aria-selected="true"] .MuiChip-root {
  font-weight: 600;
  color: white;
  background-color: black;
}

/* .privacy > * + * {
  margin-top: 1.5em;
} */

/* .privacy h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
}

.privacy p {
  font-size: 1rem;
  line-height: 1.3em;
}

.privacy h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.privacy strong {
  font-weight: bold;
}

.privacy h3 {
  font-size: 1rem;
  font-weight: bold;
}

.privacy ol {
  list-style-type: decimal;
}

.privacy ol > li + li {
  margin-top: 1.5em;
}

.privacy a {
  color: blue;
  text-decoration: underline;
}

.terms-and-conditions {
  counter-reset: section;
} */
