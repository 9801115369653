.online-snackbar {
	background-color: #db2000;
	color: white;
	text-align: center;
	padding: 16px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}
